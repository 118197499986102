import React, { ReactNode, useContext, useEffect, useState } from "react";
import { PubSub, CONNECTION_STATE_CHANGE, ConnectionState } from "@aws-amplify/pubsub";
import { Hub } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import { SettingsContext } from "../settingsContext";
import { ViewType } from "./viewType";

const defaultDeviceSettings = {
  co2_ppm: 0,
  coerced_setpoint: 0,
  controller_temp_c: 0,
  cust_max_setpoint: 0,
  date: '',
  // random initial value
  effective_altitude: 2000,
  ext_status: '',
  humidity_per: 0,
  local_elevation: 0,
  mode: 'RUN',
  o2_a_ppm: 0,
  o2_b_ppm: 0,
  pressure_mb: 0,
  remote_temp_c: 0,
  time: "",
}

const defaultDeviceShadow = {
  appearance: "LIGHT",
  brightness: 0,
  current_machine_state: "RUN" as ViewType,
  enable_schedule: false,
  lock_settings: false,
  lock_settings_pin: "0000" as string | null,
  lock_system: false,
  lock_system_pin: "0000" as string | null,
  residential_mode: false,
  residential_target: 1000,
  schedule: [] as any[],
  setpoint: 1595,
  show_alt_graph: true,
  show_co2_graph: true,
  show_stats: true
};


function defaultState() {
  return {
    initialDeviceReadingDone: false,
    deviceReadingErrored: false,
    deviceSettings: defaultDeviceSettings,
    incrementTargetAltitude: (amount?: number) => { },
    decrementTargetAltitude: (amount?: number) => { },
    publishDeviceShadow: (shadow: typeof defaultDeviceShadow) => { },
    deviceShadow: defaultDeviceShadow,
    setDeviceShadow: (shadow: typeof defaultDeviceShadow) => { },
    setDeviceReadingErrored: (err: boolean) => { },
    shadowGetResponded: false,
    setInitialDeviceReadingDone: (done: boolean) => { },
  };
}

type AltitudeState = ReturnType<typeof defaultState>;


function useAltitudeState(): AltitudeState {
  const { iotTopic } = useContext(SettingsContext);
  const [currentIotTopic, setCurrentIotTopic] = useState(iotTopic);
  // fake random init value to avoid warning
  const [initialDeviceReadingDone, setInitialDeviceReadingDone] = useState(false);
  const [deviceReadingErrored, setDeviceReadingErrored] = useState(false);
  const [shadowGetResponded, setShadowGetResponded] = useState(false);
  const [deviceSettings, setDeviceSettings] = useState({} as typeof defaultDeviceSettings);
  const [deviceShadow, setDeviceShadow] = useState({} as typeof defaultDeviceShadow);
  const [pubSubConnectionState, setPubSubConnectionState] = useState(ConnectionState.Disconnected);
  const incrementTargetAltitude = (amount?: number) => {
    const newTargetAltitude = deviceShadow.setpoint + (amount ?? 1);
    setDeviceShadow({
      ...deviceShadow,
      setpoint: newTargetAltitude
    });
  };

  const decrementTargetAltitude = (amount?: number) => {
    const newTargetAltitude = deviceShadow.setpoint - (amount ?? 1);
    setDeviceShadow({
      ...deviceShadow,
      setpoint: newTargetAltitude
    });
  };


  const publishDeviceShadow = async (shadow: typeof defaultDeviceShadow) => {
    if (!iotTopic) return;
    const url = `$aws/things/${iotTopic.thingName}/shadow/update`;
    await PubSub.publish(url, {
      "state": {
        "desired": {
          ...shadow
        }
      }
    });
  };

  // Effect to get subsequent update readings
  useEffect(() => {
    if (!currentIotTopic) return;
    const url = `$aws/things/${currentIotTopic.thingName}/shadow/update/accepted`;
    const subscription = PubSub.subscribe(url).subscribe({
      next: (data: any) => {
        // data.value.state
        const altitudeData = data.value.state.reported;
        if (!altitudeData) {
          return;
        }
        // add uuids to device schedule for scheduler
        altitudeData.schedule = altitudeData.schedule.map(
          (sched: object) => (
            {
              ...sched,
              id: uuidv4()
            }
          )
        );
        setDeviceShadow(altitudeData);
      },
      error: (error) => console.error('ERROR getting update/accept', error),
      complete: () => console.log("Done"),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [currentIotTopic]);


  // Effect to get actual device readings
  useEffect(() => {
    if (!currentIotTopic) return;
    const subscription = PubSub.subscribe(`device/${currentIotTopic.thingName}/status`).subscribe({
      next: (data) => {
        const newSettings = { ...data.value as any };
        // settings seem to "sync" more frequently so use it as shadow
        const settingsAsShadow: typeof defaultDeviceShadow = {
          ...deviceShadow,
          current_machine_state: newSettings.mode,
          setpoint: newSettings.coerced_setpoint,
        }
        if (initialDeviceReadingDone && settingsAsShadow.current_machine_state !== deviceShadow.current_machine_state) {
          setDeviceShadow(settingsAsShadow);
        }
        setDeviceSettings(newSettings);
        if (!initialDeviceReadingDone) {
          setInitialDeviceReadingDone(true);
        }
        setDeviceReadingErrored(false);
      },
      error: (error) => {
        setInitialDeviceReadingDone(true);
        setDeviceReadingErrored(true);
        console.error("ERROR in getting device settings", error)
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [currentIotTopic]);


  // useEffect(() => {
  //   if (!currentIotTopic) return;
  //   const url = `$aws/things/${currentIotTopic.thingName}/shadow/update/delta`
  //   const subscription = PubSub.subscribe(url).subscribe({
  //     next: (data: any) => {
  //       console.log("DELTA CHANGED", data, deviceShadow);
  //       if (!data.value.state) return;
  //       const newShadow = {
  //         ...deviceShadow,
  //         ...data.state
  //       }
  //       console.log("SETTING DELTA", newShadow);
  //       // add uuids to device schedule for scheduler
  //       newShadow.schedule = (newShadow.schedule || []).map(
  //         (sched) => (
  //           {
  //             ...sched,
  //             id: sched.id || uuidv4()
  //           }
  //         )
  //       );
  //       setDeviceShadow(newShadow);
  //     },
  //     error: (error) => console.error('ERROR getting update/accept', error),
  //     complete: () => console.log("Done"),
  //   });

  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // }, [currentIotTopic, deviceShadow]);

  useEffect(() => {
    const onPubSubConnection = async (payload) => {
      if (
        payload.event === CONNECTION_STATE_CHANGE
      ) {
        if (currentIotTopic && pubSubConnectionState === ConnectionState.Disconnected && payload.data.connectionState === ConnectionState.Connected) {
          setPubSubConnectionState(payload.data.connectionState);
          await PubSub.publish(`$aws/things/${currentIotTopic.thingName}/shadow/get`, {});
        }
      }
    }
    Hub.listen("pubsub", (data: any) => {
      const { payload } = data;
      onPubSubConnection(payload);
    });
  }, [currentIotTopic]);


  useEffect(() => {
    if (iotTopic?.thingName !== currentIotTopic?.thingName) {
      setCurrentIotTopic(iotTopic);
      setInitialDeviceReadingDone(false);
      setShadowGetResponded(false);
      setDeviceShadow({} as typeof defaultDeviceShadow);
      setDeviceSettings({} as typeof defaultDeviceSettings);
    }
  }, [iotTopic]);

  // initial publish to get first shadow value
  // for some reason just doing it on the effect change doesnt work
  // so use a countdown which seems way more consistent
  useEffect(() => {
    const initialGetShadow = async () => {
      await PubSub.publish(`$aws/things/${currentIotTopic.thingName}/shadow/get`, {});
    };
    if (currentIotTopic && !shadowGetResponded) {
      setTimeout(() => {
        initialGetShadow();
      }, 1000)
    }
  }, [currentIotTopic])


  // Effect to get initial device values
  useEffect(() => {
    if (!currentIotTopic) return;
    const url = `$aws/things/${currentIotTopic.thingName}/shadow/get/accepted`;
    const subscription = PubSub.subscribe(url).subscribe({
      next: (data: any) => {
        setShadowGetResponded(true);
        const altitudeData = data.value.state.reported;
        if (!altitudeData) {
          return;
        }
        altitudeData.schedule = altitudeData.schedule.map(
          (sched: object) => (
            {
              ...sched,
              id: uuidv4()
            }
          )
        );
        setDeviceShadow(altitudeData);
      },
      error: (error) => console.error('ERROR getting initial shadow', error),
      complete: () => console.log("Done"),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [currentIotTopic]);

  return {
    incrementTargetAltitude,
    decrementTargetAltitude,
    deviceSettings,
    deviceShadow,
    setDeviceShadow,
    publishDeviceShadow,
    initialDeviceReadingDone,
    deviceReadingErrored,
    setDeviceReadingErrored,
    shadowGetResponded,
    setInitialDeviceReadingDone,
  };
}

export const AltitudeContext = React.createContext(defaultState());

export function AltitudeContextProvider({ children }: { children: ReactNode }) {
  const value = useAltitudeState();
  return (
    <AltitudeContext.Provider value={value}>
      {children}
    </AltitudeContext.Provider>
  );
}
